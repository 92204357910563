import * as React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"
import { Container, Row, Col } from "react-bootstrap"
import Footer from "../../components/footer"
import "../common.css"
import Fg1 from '../../images/b1.jpg'
import Fg2 from '../../images/b2.jpg'
import Head from "../../components/header"


const BusinessPage = () => (
  <Layout>
    <Head pageTitle="Business and Services - GAZIRU, Inc." pageLang="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>Business / Services</h4>
          <h5>What is GAZIRU?</h5>
          <p>"GAZIRU" is an image recognition service. It is a basic service that enables the creation of new services that combine image recognition and information provision, such as highly accurate classification of various images, including paper, clothing, and automobiles, based on their shape and other characteristics, and delivery of additional information and advertisements on the subject through the camera of a mobile terminal such as a smart phone.</p>

          <br />

          <h5>Features</h5>
          <h6>World-class image recognition technology x General-purpose service for any recognition target</h6>
          <p>By extracting feature values (numerical value sequences) with different values for each picture from images and matching them with a database, "high accuracy" "high speed" and "versatility" can be achieved.</p>

          <h6>1 General-purpose</h6>
          <p>Image authentication is generally limited to face, fingerprint, and other authentication targets, but GAZIRU can be applied to a wide variety of standard products &mdash; industrial products &mdash; and irregular products &mdash; natural objects &mdash;.<br />
          New services and solutions can be realized by applying GAZIRU technology in a wide range of fields.</p>

          <h6>2 No dedicated equipment required</h6>
          <p>The camera attached to the smartphone can also be used to take pictures. Recognition interfaces are available from various clients.<br />
          * Depending on the object and required accuracy, a dedicated camera may be required.</p>

          <h6>3 Provided in the cloud</h6>
          <p>The service is provided on a monthly subscription basis.<br />
          No new servers or other equipment need to be purchased, and image recognition services can be launched quickly.<br />
          * "Individual identification" products (GAZIRU Z series) are also available as software packages.</p>

          <br />

          <h5>Product Introduction Video</h5>
          <br />
          <div className="moviep">
            <div className="movie"><iframe loading="lazy" title="GAZIRU + Dinolite" width="757" height="426" src="https://www.youtube.com/embed/maNtJ5ACDlk?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          </div><br />

          <br />

          <h5>Fields of application</h5>
          <img src={Fg1}></img><br />

          <br />

          <h6>Sales promotion</h6>
          <p>Using our services, you can provide media mix advertisements that link catalogs and flyers to videos and the Web, guide visitors to e-commerce sites, and offer coupons.</p>
          <h6>Determination of authenticity of branded products</h6>
          <p>With the individual identification service(GAZIRU Z), data on some of the parts of a brand-name product can be registered in advance, and later the same part of the product can be photographed to identify the individual item and determine its authenticity.</p>
          <h6>Authentic product (identical product) determination in C to C transactions</h6>
          <p>On e-commerce sites where consumers trade with each other, sellers can pre-register their products, and buyers can verify that they have received the same product by cross-checking after receiving it. This service allows for secure transactions.</p>
          <h6>Product control (traceability)</h6>
          <p>Machine parts, manufactured goods, product packages, etc., which are difficult to be tagged with individual identification tags due to size, shape, cost, etc., can be registered at the time of production to achieve traceability up to shipment in the factory and traceability after shipment for quality control, etc.</p>
          <p>For more information on application examples, please see <Link className="text-decoration-none ms-1" to="/en/examples">Case Examples</Link>.</p>

          <br />

          <h5>Flow of Service Use</h5>
          <img src={Fg2}></img><br />

          <br />

          <p>Although it depends on the contents of the system or service to be realized, the image recognition part, such as individual identification, is roughly implemented in the following flow.</p>
          <h6>1. Database Creation (Data registration)</h6>
             <p>The service provider creates a GAZIRU dictionary by registering images of objects in the database in advance. The service provider also defines actions and additional information (e.g., display of additional information, guidance to a specified advertising URL, etc.) after the object is identified, and builds the service.</p>

          <h6>2.User takes a picture of the object</h6>
             <p>The end user launches the application on a smartphone and sends the image of the object to be recognized to the GAZIRU system servers.</p>
             <p>In the case of factory traceability, etc., an imaging device installed on the line can be used to build a system that can identify many individuals at high speed by registering them under more stable imaging conditions.</p>

          <h6>3.Recognize the object</h6>
             <p>The system searches and classifies images received by the GAZIRU server and images on the pre-registered database based on feature information to recognize objects. The closer the image is taken to the time of registration, the more accurate individual identification becomes.</p>

          <h6>4.Delivering a variety of services</h6>
             <p>Based on the recognition results, we configure the application or build a system to perform the action requested by the customer. For example, additional information such as product information can be displayed on the smartphone, a link can be provided that leads to a specified advertising URL, or product lot information can be displayed on the terminal screen.</p>

          <br />

          <h5>Quotations and Inquiries</h5>
             <p>GAZIRU fees vary depending on the type of service and features used.</p>
             <p>Please feel free to contact us using the form below so that we can provide you with the best features and pricing for your needs.</p>

            <p><Link className="text-decoration-none" to="/notification/contact/en">Contact form</Link></p>

            <br />
            <p><Link className="text-decoration-none" to="/">Home</Link></p>
        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default BusinessPage
